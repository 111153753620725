<template>
  <div :class="{ spinner: true, hidden: !isLoading }"></div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes move {
  to {
    left: 100%;
  }
}

@keyframes cover {
  from,
  80% {
    z-index: 1;
  }
  100%,
  to {
    z-index: 1;
  }
}

.spinner {
  width: 1.2em;
  height: 0.6em;
  position: relative;
  margin: 20px auto;
}

/* Using pseudo elements now */
.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  left: -40%;
  width: 50%;
  height: 100%;
  border-radius: 50%;
  animation: move ease-in-out 0.65s infinite alternate,
    cover 1s infinite reverse;
}

.spinner:before {
  background: #ec6642; /* blue one */
  animation-direction: alternate-reverse, normal; /* yay! */
}

.spinner:after {
  background: #fba90d; /* pink one */
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 0.5s linear;
}
</style>>