<template>
  <div class="container-full" :class="theme">
    <Spinner class="vertical_center" :isLoading="isLoading"></Spinner>
    <router-view v-if="showHeader" name="Header" theme="light" :title="titie" :step="step" class="z_index_1000"></router-view>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Spinner from "@/components/Spinner.vue";
export default {
  components: { Spinner },
  name: "myDefault",
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("header", ["headerTitle"]),
    titie() {
      return this.$route.meta.name !== "" ? this.$route.meta.name : this.headerTitle;
    },
    theme() {
      return this.$route.meta.theme === "dark" ? "bg_" + this.$route.meta.theme : "bg_light";
    },
    showHeader() {
      return this.$route.meta.showHeader === false ? false : true;
    },
    step() {
      return this.$route.meta.step && this.$route.meta.step !== "" ? this.$route.meta.step : -1;
    },
  },
  mounted() {},
  methods: {
    ...mapActions("users", ["getUsers"]),
    ...mapActions("msg", ["getMsgStatus"]),
    ...mapActions("visitorUnauthorized", ["changeVisitorUnauthorized"]),
  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (from !== undefined) {
          this.getUsers();
          this.getMsgStatus();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("orders/setOrderNo", "");

    this.getUsers();
    this.getMsgStatus().then((res) => {
      if (res.code == "VisitorUnauthorized") {
        this.changeVisitorUnauthorized(true);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.container-full {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.vertical_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
</style>
